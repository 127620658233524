<template>
    <el-container v-if="info">
        <el-main class="left">
            <div>
                提现单号：
                <span style="margin-right:20px;">{{ info.withdraw_no }}</span>
                <el-tag>{{ status.find(v => v.val == info.status) ? status.find(v => v.val ==
                    info.status).label : '--' }}</el-tag>
            </div>
            <div class="order-info">
                <div class="title">1、提现状态</div>
                <div class="content">
                    <div class="handle-progress">
                        <div class="item" style="background: #ddddfd">
                            <div class="num" style="background: #409eff">1</div>
                            <p class="handle-text" style="color: #409eff">申请时间</p>
                            <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
                        </div>
                        <div class="line"></div>
                        <div class="item" style="background: #ddddfd">
                            <div class="num" :style="{ 'background': info.remit_time ? '#409eff' : '#c8c8cc' }">2</div>
                            <p class="handle-text" :style="{ 'color': info.remit_time ? '#409eff' : '#969699' }">审核时间</p>
                            <p class="handle-time" v-if="info.remit_time">{{ getDateformat(info.remit_time) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buyers-info">
                <div class="title">2、提现信息</div>
                <div class="box_row">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            申请金额（可结算金额）
                        </div>
                        <div class="box-card_main">
                            <div class="data1">{{ info.withdraw_amount }}元</div>
                            <div class="data2">订单金额：{{ info.order_amount }}元</div>
                        </div>
                    </el-card>
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            申请的订单商品总额
                        </div>
                        <div class="box-card_main">
                            <div class="data1">{{ info.order_goods_amount }}元</div>
                            <div class="data2">订单个数：{{ info.order_count }}个</div>
                        </div>
                    </el-card>
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            平台佣金
                        </div>
                        <div class="box-card_main">
                            <div class="data1">{{ info.rate }}%</div>
                            <div class="data2">抽成金额：{{ info.rate_amount }}元</div>
                        </div>
                    </el-card>
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            结算比例
                        </div>
                        <div class="box-card_main">
                            <div class="data1">{{ info.coupon_rate }}%</div>
                            <div class="data2">抽成金额：{{ info.coupon_rate_amount }}元</div>
                        </div>
                    </el-card>
                    <el-card class="box-card" v-if="info.status == 2">
                        <div slot="header" class="clearfix">
                            打款金额（审核通过）
                        </div>
                        <div class="box-card_main">
                            <div class="data1" style="color:red">{{ info.remit_amount }}</div>

                        </div>
                    </el-card>
                    <el-card class="box-card" v-if="info.status == -1">
                        <div slot="header" class="clearfix">
                            驳回原因
                        </div>
                        <div style="color:red">{{ info.remark }}</div>
                    </el-card>
                </div>
            </div>
            <div class="goods-info">
                <div class="title">3、流水信息</div>
                <el-form label-width="100px">
                    <el-form-item label=" ">
                        <el-input size="small" v-model="form.order_sn" placeholder="请输入订单单号"></el-input>
                    </el-form-item>
                    <el-form-item label=" " label-width="25px">
                        <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
                        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column prop="order_sn" label="订单编号" align="center"> </el-table-column>
                    <el-table-column label="订单金额" align="center">
                        <template slot-scope="scope">￥{{ scope.row.order.amount }}
                            <el-popover placement="top-start" width="400" trigger="hover">
                                <el-descriptions title=" " column="2" contentStyle="color:#409EFF">
                                    <el-descriptions-item label="订单商品总额">{{ scope.row.order.goods_amount
                                    }}</el-descriptions-item>
                                    <el-descriptions-item label="优惠券金额">-{{ scope.row.order.reduction_amount
                                    }}</el-descriptions-item>
                                    <el-descriptions-item label="运费金额">{{ scope.row.order.revise_freight_fee
                                    }}</el-descriptions-item>
                                    <el-descriptions-item label="实际支付金额">{{ scope.row.order.amount }}</el-descriptions-item>
                                </el-descriptions>
                                <i style="font-size:16px;color:#409EFF" class="el-icon-question" slot="reference"></i>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="可结算金额" align="center">
                        <template slot-scope="scope">￥{{ scope.row.money }}</template>
                    </el-table-column>
                    <el-table-column label="订单时间" align="center">
                        <template slot-scope="scope">{{ getDateformat(scope.row.create_time) }}</template>
                    </el-table-column>
                </el-table>
                <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
            </div>
        </el-main>
    </el-container>
</template>
  
<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging
    },
    data () {
        return {
            id: '',
            info: {},
            status: [
                {
                    val: -1,
                    label: '已驳回'
                },
                {
                    val: 1,
                    label: '待审核'
                },
                {
                    val: 2,
                    label: '已结算'
                }
            ],
            form: {
                page: 1,
                rows: 10,
                id: '',
                order_sn: ''
            },
            list: [],
            total_number: 0
        };
    },
    created () {
        this.id = this.$route.query.id;
        this.form.id = this.id
        this.getInfo();
        this.getList()
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getInfo () {
            this.$axios.post(this.$api.finance.withdrawInfo, { id: this.id }).then(res => {
                if (res.code == 0) {
                    this.info = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        getList (page = 0) {
            if (page) {
                this.form.page = 1
            }
            this.$axios.post(this.$api.finance.withdrawInfoList, this.form).then(res => {
                if (res.code == 0) {
                    let { list, total_number } = res.result
                    this.list = list;
                    this.total_number = total_number
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                id: this.id,
                order_sn: ''
            }
            this.getList()
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-container {
    font-family: Microsoft YaHei;
    font-size: 14px;
    color: #17171a;
}

.title {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
}

.left {
    margin-right: 8px;
    background: #fff;

    .order-info {
        margin-bottom: 30px;
    }

    .order-info .content {
        .order-num {
            display: flex;
            height: 47px;
            background: #f0f0f5;
            align-items: center;
            padding-left: 24px;

            li {
                font-size: 14px;
                margin-right: 40px;

                span {
                    font-weight: 400;
                    color: #17171a;
                }
            }
        }

        .handle-progress {
            display: flex;
            justify-content: center;
            height: 190px;
            align-items: center;

            .line {
                height: 4px;
                width: 300px;
                background: #f0f0f5;
            }

            .item {
                background: #fff;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .num {
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    background: #c8c8cc;
                    border-radius: 50%;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: bold;
                }

                .handle-text {
                    width: 100px;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -25px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #969699;
                }

                .handle-time {
                    width: 120px;
                    position: absolute;
                    left: 50%;
                    text-align: center;
                    transform: translateX(-50%);
                    bottom: -25px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #969699;
                }
            }
        }

        .handle-status {
            padding: 30px 0;
            background: #f0f0f5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .status {
                font-size: 16px;
                font-weight: bold;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: #969699;
            }

            .btn {
                margin-top: 18px;
            }

            .order-actions {
                margin-top: 10px;
            }
        }
    }

    .buyers-info {
        margin-bottom: 30px;

        .content {
            margin-top: 24px;
            padding-left: 40px;

            div {
                display: flex;

                span {
                    font-weight: 400;
                }

                p {
                    width: 240px;

                    &:last-child {
                        width: auto;
                    }
                }

                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .goods-info .content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .price {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            margin-top: 20px;
            border-bottom: 1px solid #f5f5fa;
            color: #969699;

            span:last-child {
                color: #17171a;
                width: 80px;
                text-align: right;
            }

            p {
                display: flex;
                margin-bottom: 5px;
            }
        }

        .goods-title {
            display: flex;
            justify-content: center;
            align-items: center;

            .el-image {
                width: 68px;
                height: 68px;
                border-radius: 8px;
                margin-right: 8px;
            }

            p {
                flex: 1;
            }
        }

        .total-price {
            font-weight: bold;
            margin-top: 5px;
            display: flex;

            span:last-child {
                text-align: right;
                font-weight: 400;
                color: #fd5e94;
                width: 80px;
            }
        }
    }
}

.box_row {
    display: flex;
    margin-top: 20px;

    .box-card+.box-card {
        margin-left: 30px;
    }

    .box-card {
        width: 240px;

        .clearfix {
            text-align: center;
        }
    }

    .box-card_main {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .data1 {
            font-size: 26px;
            font-weight: bold;
        }

        .data2 {
            margin-top: 20px;
        }
    }
}

.el-form {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .el-input {
        width: 300px;
    }
}
</style>
  